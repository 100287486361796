

/* ============================================= */

.main-box{
    margin: 15px;
    box-shadow: 0px 0px 16px 0px #00000014;

}

.common-box{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.common-box2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 356px;
}

  /* rscore css */

  .r-box{
    height: 245px;
    /* background-color: #233DFF; */
   
 }

  .r-title-box{
    height: 44px;
    border-bottom: 1px solid #EDEDED;
    background-color: #FBFBFF;  
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
 }

 .custom-list {
    padding: 0;
    
  }
  
  .custom-list li {
    list-style-type: disc;
    margin-left: 13px;
    margin-bottom: 10px; /* Optional: Add some space between list items */
  }

  .r-content-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 200px;
    /* padding: 15px; */
  }

  .overall-score{
    width: 636px;
    height: 146px;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    box-shadow: 0px 4px 16px 0px #233DFF14 !important;
text-align: center;
  }

  .initial-target-score{
    width: 135px;
    height: 77px;
    padding: 0px, 20px, 0px, 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .current-score{
    width: 135px;
    height: 94px;
    padding: 0px, 20px, 0px, 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .r-line{
    width: 75px;
    height: 66px;
    align-items: center;
  }

  .overall-score-detail{
    width: 381px;
    height: 146px;
    border-radius: 5px;
    padding: 10px 12px 10px 12px;
    box-shadow: 0px 4px 16px 0px #233DFF14 !important;
text-align: center;
  }

  .initial-target-score-detail{
    width: 105px;
    height: 77px;
    padding: 0px, 20px, 0px, 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .current-score-detail{
    width: 107px;
    height: 94px;
    padding: 0px, 20px, 0px, 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .r-line-detail{
    width: 25px;
    height: 29px;
    align-items: center;
  }

  .detail-other-box{
    width: 165px;
    height: 108px;
    border-radius: 5px;
    padding: 10px 12px 10px 12px;
    box-shadow: 0px 4px 16px 0px #233DFF14 !important;
text-align: center;
  }

  .initial-other-detail{
    width: 40px;
    height: 43px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .current-other-detail{
    width: 45px;
    height: 55px;
    padding: 0px, 20px, 0px, 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }


  /* action css */

  .action-box{
    height: 388px;
    width: 33.3%;
    /* background-color: #233DFF; */
   
 }

 .up-action-box{
    height: 388px;
    width: 33.3%;
    border-width: 0px 1px 0px 1px;
    border-style: solid;  
    border-color: #EDEDED;
    
    
    /* background-color: #233DFF; */
   
 }



 /* legend css */
 .legend-box{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  width: 100px;
 }