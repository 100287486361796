.main-box{
    margin: 15px;
    box-shadow: 0px 0px 16px 0px #00000014;

}

/*==================== action overview css ======================= */

.action-graph-box{
    /* border: 1px solid black; */
    /* width: 75%; */
    /* height: 290px; */
    padding: 30px;
    padding-left: 0px;
    padding-bottom: 20px;
}

.action-overdue-box{
    /* border: 1px solid green; */
    width: 25%;
    /* height: 100%; */
    border-left: 1px solid #EDEDED;
}

.action-cat-box{
    /* border: 1px solid red; */
   flex: 1;
   height: 319px;
}

.your-action-box{ 
   flex: 1;
   height: 320px;
   border-left: 1px solid #EDEDED;
}

.action-title-box{
    height: 44px;
    border-bottom: 1px solid #EDEDED;
    background-color: #F9F9F9;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 20px 0px 20px;
    justify-content: space-between;
}


 /* legend css */
 .lege-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    width: 95px;
   }

/*============================= action chart css=============== */
.action-chart-box{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.filter-box{
    width: 200px;
    border-right: 1px solid #EDEDED;
}

.filter-common-box{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.filter-title-box{
    height: 40px;
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
    padding: 0px 20px 0px 20px;    
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.filter-category-box{
    padding: 10px 20px 0px 20px;    
}

.filter-category{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.month-main-box{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.month-box{
    /* padding: 20px; */
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
}


.month-title-box{
    height: 40px;
    background-color: #F9F9F9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.month-content-box{
    min-height: 116px;
    width: 266.67px;
    border-radius: 5px;
    border: 1px solid #D4D4D4;
    padding: 20px 10px 20px 10px;
    gap: 10px;
    margin-top: 20px;
    margin-right: 30px;
    margin-left: 30px;
    
}

.month-circle{
    width: 17px;
    height: 17px;
    border-radius: 50%;
    /* background-color: #0263FF; */
}

.month-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

