   /* ========================new design csss======================= */

.main-outer-div{
  padding: 2.7vw;
  /* padding-left: 45px; */
  /* height: 608px; */
  padding-top: 7.5vw;
}

.title-div{
    /* width: 627px; */
    /* width: 90%; Adjust width using percentage */
   }
.title2-div{
  margin-top: 60px;
  /* position: relative; */
  /* top: -80px; */
}


.main-title{
font-family: Manrope;
font-size: 54px;
font-weight: 800;
line-height: 74px;
letter-spacing: 0em;
text-align: left;
color: #233DFF;
   }

.sub-title{
font-family: Manrope;
font-size: 22px;
font-weight: 700;
line-height: 30.05px;
color: #1E2429;
   }

.note-title{
  font-size: 16px;
  line-height: 21.86px;
  font-weight: 500;
  color: #66696C;
  margin-bottom: 13px !important;
}

.get-started-btn{
  width: 143px;
height: 46px;
padding: 12px, 26px, 12px, 26px;
border-radius: 5px;
gap: 10px;

}

.box-main-div{
  width: 470px;
  height: 374px;
}

.horizontal-scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; /* Optional: Enable smooth scrolling on iOS */
}


.box-div{
  
  width: 253px;
  height: 172px;
  border-radius: 5px;
  padding: 10px 10px 15px 10px;
  box-shadow: 0px 4px 16px 0px #00000014;
  white-space: normal; /* Resetting white-space to allow multiline text */
  margin-right: 10px; 
}


/* For smaller screens */
@media (max-width: 768px) {
  .main-title {
    font-size: 39px;
    line-height: 59px;
  }
  .title-div{
    width: 324px;
    text-align: center;
    margin-left: 20px;
  }
  .title2-div{
    top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .box-main-div{
    width: 340px;
    height: 234px;
    overflow-x: auto;
    white-space: nowrap;
  }
  .box-div{
    min-width: 253px;
    width: 253px;
    height: 172px;
    border-radius: 5px;
    padding: 10px 10px 15px 10px;
    box-shadow: 0px 4px 16px 0px #00000014;
    white-space: normal; /* Resetting white-space to allow multiline text */
    margin-right: 10px; 
  }
}