.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 690px;
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid lightgray; /* Blue color */
  }
  
  .title {
    font-weight: bold;
    font-size: 16px;
    color: black; /* Blue color */
  }
  
  .input-container {
    display: flex;
    align-items: center;
  }
  
  .input-field {
    border: none;
    outline: none;
    font-size: 16px;
    color: #0000ff; /* Blue color */
  }
  
  .input-field::placeholder {
    color: #0000ff; /* Blue color */
  }
  .input-container {
    display: flex;
    align-items: center;
  }
  
  .input-text {
    border-bottom: 2px solid #0000ff; /* Blue color */
    padding: 5px; /* Adjust padding as needed */
    color: #0000ff;
  }
  