
.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

td, th {
  text-align: left;
  width:0.1%;
  white-space: nowrap;  
}

.plain-list {
  list-style-type: none
}

.logo{
  width: 162.92px;
  height: 52px;
  /* background-image: url('../assets/Logo.png'); */
}

.login-btn{
  padding: 8px 20px 8px 20px !important;
  width: 80px;
  height: 35px;
  border-radius: 4px !important;
}

.nav-btns{
font-size: 14px;
font-weight: 600;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF !important;
border-color: #000706 !important;
}

.login-btn.selected,
.nav-btns.selected {
  text-decoration: underline;
}




 .navbarColor{
  /* display: flex; */
  /* justify-content: space-between !important; */
  background-color: #000706;
  /* padding-left: 45px; */
  /* padding-right: 45px; */
}


/* For smaller screens */
/* @media (max-width: 768px) {
  .navbar {
    width: max-content !important;
  }
}  */